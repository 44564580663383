/* CUSTOM CSS */

.status-circle-color{
  width: 12px;
  height: 12px;
  border-radius: 10px;
  top: 3px;
  margin-right: 5px;
  display: inline-block;
  &.red{
    background-color: #e74c3c;
  }
  &.green{
    background-color: #27ae60;
  }
  &.gray{
    background-color: #95a5a6;
  }
  &.orange{
    background-color: #e67e22
  }
  &.yellow{
    background-color: #FFFF00;
  }
}
